<template>
  <v-menu
    v-model="menu"
    offset-y
    content-class="custom-drop-down-immo"
    :close-on-content-click="closeOnContentClick"
    :nudge-bottom="8"
    :nudge-width="nudgeWidth"
    @input="clickMenu"
  >
    <template v-slot:activator="{ on }">
      <span v-on="on" class="d-inherit" :class="styleComponent">
        <slot name="header" v-on="on">default </slot>
      </span>
    </template>
    <div class="custom-drop-down-immo-body"><slot name="body"> </slot></div>
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnContentClick: {
      default: false
    },
    nudgeWidth: {
      default: 0
    },
    styleComponent: { required: false },
    closeCustemDropMenu: {
      default: false,
      required: false
    }
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    clickMenu(event) {
      this.$emit('eventMenu', event)
    }
  },
  watch: {
    closeCustemDropMenu() {
      this.menu = false
    }
  }
}
</script>

<style scoped lang="scss">
.v-menu__content:has(.custom-drop-down-immo-body) {
  width: 13rem;
  box-shadow: none !important;
  background: #fff;
  border: 1px solid #ece8f4;
  padding: 8px;
  border-radius: 8px;
  // min-width: 220px !important;
  font-family: 'Montserrat', Arial !important;
}
.v-menu__content:has(.item-add-component),
.v-menu__content:has(.dropdown-edit-view-list) {
  padding: 4px 0px;
}
.d-inherit {
  display: inherit;
}
</style>
